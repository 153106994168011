<template>
  <div class="container-fluid d-block d-md-none sticky-top" id="mobile-nav">
    <ul class="nav justify-content-between align-items-center">
      <li class="nav-item">
        <a class="nav-link mobile-socils-icon" href="https://github.com/levpopov1">
          <i class="bi-github"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link mobile-socils-icon" href="https://twitter.com/levpopov_dev">
          <i class="bi-twitter"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#home">
          <img src="@/assets/logo.svg" width="40" height="40">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link mobile-socils-icon" href="https://www.linkedin.com/in/lev-popov/">
          <i class="bi-linkedin"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link mobile-socils-icon" href="mailto:hello@levpopov.dev">
          <i class="bi-envelope"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'NavbarMobile'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  #mobile-nav{
    background-color: #000;
  }

  .mobile-socils-icon {
    font-size: 20px;
    color: #ffffff;

    &:hover,
    :active {
      color: var(--bs-primary);
    }
  }

  i {
    line-height: 1;
    align-self: center;
    vertical-align: text-top;
  }
</style>