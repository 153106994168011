<template>
  <div id="app">
    <NavbarMobile/>
    <!-- <router-view/> -->
    <Home/>
    <!-- <SocialsBarMobile/> -->
    <Footer/>
  </div>
</template>

<script>
import NavbarMobile from '@/components/Navbar-Mobile'
// import SocialsBarMobile from '@/components/Socials'
import Footer from '@/components/Footer'
import Home from '@/views/Home'
export default {
  name: "App",
  components: {
    NavbarMobile,
    Home,
    // SocialsBarMobile,
    Footer
  },
  mounted(){
    function smoothScroll(event){
        event.preventDefault();
        let location = this.getAttribute('href');
        let yOffset = 56;
        let y = location == '#home' ? 0 : document.querySelector(location).offsetTop - yOffset;
        window.scrollTo({
          behavior: 'smooth',
          top: y
        });
    }

    let links = document.querySelectorAll('a[href*="#"]');
    links.forEach(element => {
      return element.addEventListener('click', smoothScroll);
    });

  }
}
</script>

<style lang="scss">
  @import './assets/scss/index.scss';
</style>