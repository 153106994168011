<template>
  <div id="home">
    <div class="container-fluid">
      <div class="row">
        <Sidebar />
        <div class="col-sm-12" id="main-content">
          <div class="row justify-content-center justify-content-lg-start">
            <div class="col-sm-12 col-md-10 col-lg-12 mb-3 mt-3">
              <div class="card title-card" id="aboutMe">
                <div class="card-body p-3 p-md-5 w-lg-50">
                  <div class="panel-upper p-3 text-center text-lg-start">
                    <h1 class="display-2">Hi, I'm Lev</h1>
                    <p class="lead fw-bold typewriter mb-0" data-period="2000" data-type='["Software Developer.", "keyboard enthusiast.", "playing card collector.", "tech nerd."]'>
                      <span class="stay">I am a Software Developer.</span><span class="wrap"></span>
                    </p>
                  </div>
                  <div class="panel-center text-center d-block d-lg-none">
                    <img src="../assets/img/profile-pic.png" alt="Me" id="profile-img">
                  </div>
                  <div class="panel-lower d-grid p-3 d-lg-none">
                    <a href="#projects" class="text-warning text-center text-decoration-none fw-bold">
                      My Projects<br>
                      <i class="bi-arrow-down-short display-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center justify-content-lg-start" id="projects">
            <div class="col-sm-12 col-md-10 col-lg-6 col-xxl-4 mb-3">
              <div class="gradient-border-pink h-100">
                <div class="card project-card h-100" id="languageQuiz">
                  <div class="card-body p-3 p-md-5 d-flex flex-column justify-content-between card-bg-pink">
                    <div class="panel-upper p-3">
                      <h1 class="display-2">Language Quiz</h1>
                      <p class="lead">Learn Hiragana, Katakana and Hangul alphabets in a Japanese and Korean language quiz.</p>
                      <p class="card-text">
                        I have been interested in learning Japanese for a long time. A daunting endevour given the
                        complexity of the language, with its three alphabets, complex honorifics and phonetic pronounciation.
                      </p>
                      <p class="card-text">
                        I decided to start small and learn the alphabet first, focusing on being able to read Hiragana and Katakana.
                        To help me with this, I built this flashcard style quiz in hopes of making the symbol memorization process easier.
                      </p>
                      <p class="card-text">
                        This project was also a good candidate for me to try VueJs for the first time, 
                        as I thought it would be a great fit for this type of interactive app.
                      </p>
                      <p class="card-text">
                        The app was built with a VueJs frontend and NodeJS backend to support additional features in the future.
                        These will include user accounts, personal progress tracking, scoreboards and more.
                      </p>
                      <p class="card-text">
                        <a href="https://github.com/levpopov1/language-quiz" target="_blank" rel="noreferrer" class="card-link link-light">
                          <i class="bi bi-github gh-source"></i>
                          <span>View project on Github</span>
                        </a>
                      </p>
                    </div>
                    <div class="panel-lower d-grid p-3">
                      <!-- <a href="#" class="btn btn-outline-light disabled" target="_blank">Live demo coming soon</a> -->
                      <a href="https://www.logograms.fyi/" class="btn btn-light" target="_blank">Live Demo</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-6 col-xxl-4 mb-3">
              <div class="gradient-border-purple h-100">
                <div class="card project-card h-100" id="esotericCards">
                  <div class="card-body p-3 p-md-5 d-flex flex-column justify-content-between card-bg-purple">
                    <div class="panel-upper p-3">
                      <h1 class="display-2">Esoteric Cards</h1>
                      <p class="lead">The biggest encyclopedia of playing cards on the internet.</p>
                      <p class="card-text">
                        A few years ago I found a new hobby - collecting custom decks of playing cards.
                      </p>
                      <p class="card-text">
                        It all started when I saw a cardistry video on Youtube and noticed they used a non-standard deck of playing cards which was very beautifully designed.
                        Naturall, I bought a few decks that I particularly liked, and very soon my collection had grown to over 30.
                      </p>
                      <p class="card-text">
                        Given that I could never afford to buy every deck of custom playing cards in existance 
                        I set out to build a web app that would serve as an encyclopedia to showcase evey card in its full, high resolution glory. 
                      </p>
                      <p class="card-text">
                        The projects frontend is a VueJS app which communicates with an API I built using NodeJS and MongoDB allowing me to scale up the project in the future. 
                      </p>
                      <p class="card-text">
                        At this stage, the project is still in early development but my goal is to one day make it the go-to place for all playing card enthusiasts looking to pick up a fresh new deck.
                      </p>
                      <p class="card-text">
                        <a href="https://github.com/levpopov1/esoteric-cards" target="_blank" rel="noreferrer" class="card-link link-light">
                          <i class="bi bi-github gh-source"></i>
                          <span>View project on Github</span>
                        </a>
                      </p>
                    </div>
                    <div class="panel-lower d-grid p-3">
                      <a href="#" class="btn btn-outline-light disabled" target="_blank">Live demo coming soon</a>
                      <!-- <a href="#" class="btn btn-dark" target="_blank">Live Demo</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-6 col-xxl-4 mb-3">
              <div class="gradient-border-blue h-100">
                <div class="card project-card h-100" id="inspiredThemes">
                  <div class="card-body p-3 p-md-5 d-flex flex-column justify-content-between card-bg-blue">
                    <div class="panel-upper p-3">
                      <h1 class="display-2">Inspired Themes</h1>
                      <p class="lead">Generate UI color themes from inspiring artworks.</p>
                      <p class="card-text">
                        When starting work on a new project I like to create mood boards of artworks I find inspiring, using that inspiration as the basis for designing my project.
                      </p>
                      <p class="card-text">
                        As someone who follows a lot of artists on Twitter, I often stumble across all kinds of artworks from up-and-coming artists and well known creators. Seeing these, I am often inspired to create something of my own.  
                      </p>
                      <p class="card-text">
                        That's how I came up with the idea to build this project. It allows users to auto-generate themes from their favourite artworks. 
                      </p>
                      <p class="card-text">
                        This project is still under construction but I plan to use React for the frontend application and NodeJS to handle image upload and manipulation on the backend.
                        I will make use of a library like ImageMagic to sample colors from a user submitted image and generate a theme based on these. 
                      </p>
                      <p class="card-text">
                        <a href="https://github.com/levpopov1/inspired-themes" target="_blank" rel="noreferrer" class="card-link link-light">
                          <i class="bi bi-github gh-source"></i>
                          <span>View project on Github</span>
                        </a>
                      </p>
                    </div>
                    <div class="panel-lower d-grid p-3">
                      <!-- <a href="#" class="btn btn-outline-light disabled" target="_blank">Live demo coming soon</a> -->
                      <a href="https://themes.levpopov.dev/" class="btn btn-warning" target="_blank">Live Demo</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Sidebar from '@/components/Sidebar'

  export default {
    name: 'Home',
    components: {
      Sidebar
    },
    mounted(){
      var TxtType = function(el, toRotate, period) {
          this.toRotate = toRotate;
          this.el = el;
          this.loopNum = 0;
          this.period = parseInt(period, 10) || 2000;
          this.txt = '';
          this.tick();
          this.isDeleting = false;
      };

      TxtType.prototype.tick = function() {
          var i = this.loopNum % this.toRotate.length;
          var fullTxt = this.toRotate[i];

          if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
          } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
          }

          this.el.innerHTML = '<span class="stay">I am a </span><span class="wrap">' + this.txt + '</span>';

          var that = this;
          var delta = 200 - Math.random() * 100;

          if (this.isDeleting) { delta /= 2; }

          if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
          } else if (this.isDeleting && this.txt === '') {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
          }

          setTimeout(function() {
            that.tick();
          }, delta);
      };

      var elements = document.getElementsByClassName('typewriter');
      for (var i=0; i<elements.length; i++) {
          var toRotate = elements[i].getAttribute('data-type');
          var period = elements[i].getAttribute('data-period');
          if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
          }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewriter > .wrap { border-right: 0.08em solid #000}";
      document.body.appendChild(css);
    }
  }
</script>

<style lang="scss" scoped>

  .card{
    border: none;
    color: #111;
  }

  .project-card{
    min-height: calc(80vh - 1rem - 2px);
    background: #000;
    color: white;
  }

  .title-card{
    min-height: calc(20vh - 2rem);
    background: url('../assets/patterns/dot-grid.png');
  }

  .gradient-border-pink{
    background: linear-gradient(to right, #bb2ff7, #9026f5);
    padding: 1px;
    border-radius: 0.25rem;
  }

  .gradient-border-purple{
    background: linear-gradient(to right, #8524f5, #591af3);
    padding: 1px;
    border-radius: 0.25rem;
  }

  .gradient-border-blue{
    background: linear-gradient(to right, #5019f3, #230ff1);
    padding: 1px;
    border-radius: 0.25rem;
  }

  .card-bg-pink{
    background: url('../assets/img/waves-big.png') left no-repeat;
    background-size: cover;
  }
  
  .card-bg-purple{
    background: url('../assets/img/waves-big.png') center no-repeat;
    background-size: cover;
  }
  
  .card-bg-blue{
    background: url('../assets/img/waves-big.png') right no-repeat;
    background-size: cover;
  }

  .gh-source{
    font-size: 1.5rem;
    margin: 0 1rem 0 0;
  }

  #profile-img{
    width: 100%;
    max-height: 300px;
    max-width: 300px;
    // object-fit: cover;
  }

  @media (min-width: 768px){
    #main-content{
      padding-left: 96px;
    }
  }

  @media (min-width: 992px){
    .w-lg-50{
      width: 50%;
    }
  }

  @media (min-width: 768px) and (max-width: 992px){
    .card{
      min-height: calc(60vh - 25px);
    }
  }

</style>
