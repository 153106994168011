<template>
    <div class="container-fluid d-block d-md-none">
      <div class="row">
        <div class="col-sm-12">
          <p class="text-muted font-monospace text-center py-5">
            Designed and developed by Lev Popov.
            2021
          </p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style>

</style>