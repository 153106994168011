import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import smoothScroll from 'smoothscroll-polyfill';

smoothScroll.polyfill();
Vue.config.productionTip = false

new Vue({
  // router,
  render: h => h(App)
}).$mount('#app')
