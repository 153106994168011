<template>
  <div>
    <div class="sidebar d-none d-md-flex flex-column align-items-center justify-content-between my-3 fixed-top">
      <ul class="nav flex-column text-center">
        <li class="nav-item">
          <a class="nav-link" href="#home" id="home-link">
            <img src="@/assets/logo.svg" width="40" height="40">
          </a>
        </li>
      </ul>
      <ul class="nav flex-column text-center">
        <li class="nav-item">
          <a class="nav-link sidebar-icon" href="https://www.linkedin.com/in/lev-popov/">
            <i class="bi-linkedin"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link sidebar-icon" href="https://github.com/levpopov1">
            <i class="bi-github"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link sidebar-icon" href="https://twitter.com/levpopov_dev">
            <i class="bi-twitter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link sidebar-icon" href="https://stackshare.io/levpopov1/my-current-stack">
            <i class="bi-stack"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link sidebar-icon" href="mailto:hello@levpopov.dev">
            <i class="bi-envelope"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Sidebar",
  }
</script>

<style lang="scss" scoped>
  .sidebar{
    // width: 66px;
    height: calc(100vh - 2rem);
    border-radius: 0.25rem;
    background-color: #111;
  }
  .sidebar-icon{
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ffffff;
    &:hover, :active{
      color: var(--bs-primary);
    }
  }
  #home-link{
    margin-top: 10px;
  }
  .fixed-top{
    left: unset;
    right: unset;
    // top: 1rem;
  }
  .fixed-bottom{
    left: unset;
    right: unset;
    bottom: 1rem;
  }
</style>